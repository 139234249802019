<template>
  <div
    id="app"
    class="container grid min-h-screen p-8 mx-auto xl:p-0 place-content-center"
  >
    <div class="mb-8">
      <div class="flex flex-col lg:w-1/3">
        <h1 class="font-sans text-6xl font-bold tracking-tighter">
          <span>Ad</span><span class="text-gray-500">Viewr.</span>
        </h1>
        <p class="flex sm:block">
          <span
            class="inline-block px-3 py-1 text-xs font-bold text-white uppercase bg-gray-500 "
          >
            Ad Preview Images
          </span>
          <span
            class="inline-block px-3 py-1 text-xs font-bold text-threesides-red"
          >
            by Threesides
          </span>
        </p>
      </div>
    </div>
    <div
      v-if="message"
      class="p-4 my-4"
      :class="{
        'bg-red-300 text-red-800': error,
        'bg-green-300 text-green-800': success,
      }"
    >
      {{ message }}
    </div>
    <div
      class="grid grid-rows-3 gap-4 md:grid-rows-1 md:grid-cols-2 lg:grid-cols-3"
    >
      <div class="md:col-span-2 lg:col-span-1">
        <p><strong class="text-2xl font-bold text-gray-500">1.</strong></p>
        <p class="mb-8 text-sm text-gray-600">
          Click to select file, or Ctrl + V to paste image from clipboard.
          <br />
          Use
          <code
            class="px-2 py-1 font-mono text-sm text-gray-900 bg-gray-300 rounded-sm "
            >Windows + Shift + S</code
          >
          to capture a screenshot.
        </p>
        <file-input
          :accept="'image/*'"
          :detectPaste="true"
          :fullWindow="true"
          :imagePreview="true"
          :multiple="false"
          @change="onFileInputChange"
          @error="onFileError"
        >
          <template> Drag or Paste image here </template>
        </file-input>
      </div>
      <div>
        <p><strong class="text-2xl font-bold text-gray-500">2.</strong></p>
        <p class="mb-8 text-sm text-gray-600">
          Preview the uploaded image here.
        </p>
        <img
          :src="image"
          alt=""
          srcset=""
          class="border border-black border-dotted"
        />
      </div>
      <div>
        <p><strong class="text-2xl font-bold text-gray-500">3.</strong></p>
        <p class="mb-8 text-sm text-gray-600">
          Copy and paste the image url here.
        </p>
        <div class="flex items-center gap-2">
          <input
            type="text"
            name="fileURL"
            id="fileURL"
            readonly
            :value="image"
            class="w-11/12"
            @click="selectText"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 cursor-pointer"
            :class="{ 'text-green-300': copied }"
            viewBox="0 0 20 20"
            fill="currentColor"
            ref="fileURL"
            @click="copyImageToClipboard"
          >
            <path
              d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z"
            />
            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
          </svg>
        </div>
      </div>
    </div>
    <footer class="mt-16">
      <p class="text-xs text-center text-gray-400">
        Built by
        <a
          href="https://mathiaseverson.com"
          class="px-px transition-colors border-b border-transparent hover:text-gray-600 hover:border-gray-600"
          >Mathias</a
        >
        for
        <a
          class="px-px transition-colors border-b border-transparent hover:text-threesides-red hover:border-threesides-red"
          href="https://threesides.com.au"
          >Threesides Marketing</a
        >
        | Any media upload via this site must meet
        <a
          class="px-px transition-colors border-b border-transparent hover:text-gray-600 hover:border-gray-600"
          href="https://cloud.google.com/terms"
          >Google's Terms</a
        >
        and
        <a
          class="px-px transition-colors border-b border-transparent hover:text-gray-600 hover:border-gray-600"
          href="https://cloud.google.com/terms/aup"
          >AUP</a
        >
      </p>
    </footer>
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";

import FileInput from "@/components/FileInput.vue";
import app from "../firebase.config";
const storage = getStorage(app);

import { v4 } from "uuid";

export default {
  name: "App",
  data: () => ({
    image: "",
    imageData: "",
    message: "",
    copied: false,
    error: false,
    success: false,
  }),
  components: {
    FileInput,
  },
  mounted() {
    getDownloadURL(ref(storage, "preview.jpg")).then((url) => {
      this.image = url;
    });
  },
  methods: {
    selectText(e) {
      e.target.select();
    },
    onFileError(e) {
      this.error = true;
      this.success = false;
      this.message = e;
    },
    onFileInputChange(e) {
      this.imageData = e.detail.files[0];

      let reader = new FileReader();
      try {
        //Try Read as image
        reader.readAsDataURL(this.imageData);
      } catch (error) {
        this.error = true;
        this.message = "Invalid File. Please use an image instead";
      }

      if (!this.error) {
        this.error = false;
        this.message = "Uploading image";
        let newImage = ref(storage, v4());

        uploadBytes(newImage, this.imageData).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            this.image = url;
            this.message = "Uploaded Image";
            this.success = true;
            this.trackEvent("upload_image", { url });
          });
        });
      }
    },
    copyImageToClipboard() {
      navigator.clipboard.writeText(this.image);
      this.copied = true;
      this.message = "Image URL copied to clipboard";
    },

    trackEvent(name, data) {
      const analytics = getAnalytics();
      logEvent(analytics, name, data);
    },
  },
};
</script>
