import Vue from "vue";
import App from "./App.vue";
import "@/css/main.css";
// import "@/firebase";
// import firebaseui from "firebaseui";
// var ui = new firebaseui.auth.AuthUI(firebase.auth());

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
